<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('tax_report.branch') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('tax_report.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('tax_report.tax') }}</label>
                <multiselect v-model="taxes_ids"
                             :placeholder="$t('tax_report.tax')"
                             label="name"
                             track-by="id"
                             :options="taxes"
                             :multiple="false"
                             @input="getIds('taxes')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getTaxes($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('currency') }}</label>
                <select id="currency_id" v-model="filters.f_currencies_ids" class="custom-select" name="">
                  <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('tax_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('tax_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mt-3">
          <div class="row mb-10">
            <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
              <h4 class="text-center">{{ $t('MENU.tax_declaration_report') }}</h4>
              <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
            </div>

          </div>
          <div class="row mb-10">
            <div class="col-12">
              <b-tabs content-class="mt-3">
                <b-tab :title="$t('details')" active>
                  <div class="table-responsive">
                    <div  class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('DetailsTable')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>

                        <button class="dropdown-item" @click="printData('DetailsTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExportDetails('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>

                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="DetailsTable">
                      <tbody>
                      <tr>
                        <th colspan="4">{{$t('tax_declaration_report.sales')}}</th>
                      </tr>
                      <tr>
                        <th>{{$t('tax_declaration_report.tax')}}</th>
                        <th>{{$t('tax_declaration_report.sales')}}</th>
                        <th>{{$t('tax_declaration_report.edits')}}</th>
                        <th>{{$t('tax_declaration_report.tax_due')}}</th>
                      </tr>

                      <template v-if="data_details[0] && data_details[0].items">
                        <tr  v-for="(_row, _index) in data_details[0].items" :key="'sales'+_index">
                          <th>{{_row.tax_name}}</th>
                          <th>{{_row.total_invoice_applied_amount}}</th>
                          <th>{{_row.total_credit_applied_amount}}</th>
                          <th>{{_row.tax_values}}</th>
                        </tr>

                      </template>
                      <tr v-if="data_details[0]">
                        <th>{{ $t('tax_report.total') }}</th>
                        <th>{{data_details[0].total_invoice_applied_amount}}</th>
                        <th>{{data_details[0].total_credit_applied_amount}}</th>
                        <th>{{data_details[0].total_tax_values}}</th>
                      </tr>
                      <tr>
                        <th colspan="4">{{$t('tax_declaration_report.purchases')}}</th>
                      </tr>
                      <tr>
                        <th>{{$t('tax_declaration_report.tax')}}</th>
                        <th>{{$t('tax_declaration_report.purchases')}}</th>
                        <th>{{$t('tax_declaration_report.edits')}}</th>
                        <th>{{$t('tax_declaration_report.payable_tax')}}</th>
                      </tr>
                      <template v-if="data_details[1] && data_details[1].items">
                        <tr  v-for="(_row, _index) in data_details[1].items" :key="'sales'+_index">
                          <th>{{_row.tax_name}}</th>
                          <th>{{_row.total_invoice_applied_amount}}</th>
                          <th>{{_row.total_credit_applied_amount}}</th>
                          <th>{{_row.tax_values}}</th>
                        </tr>
                      </template>
                      <tr v-if="data_details[1]">
                        <th>{{ $t('tax_report.total') }}</th>
                        <th>{{data_details[1].total_invoice_applied_amount}}</th>
                        <th>{{data_details[1].total_credit_applied_amount}}</th>
                        <th>{{data_details[1].total_tax_values}}</th>
                      </tr>


                      </tbody>
                      <tfoot>
                      <tr>
                        <th colspan="3">{{ $t('tax_report.total') }}</th>
                        <td><b>{{ details_total.total_tax_values ? details_total.total_tax_values : 0 }}</b></td>
                      </tr>
                      </tfoot>

                    </table>
                  </div>
                  <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--end::supplier-->
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportDetails">
          <tbody>
          <tr>
            <th colspan="4">{{$t('tax_declaration_report.sales')}}</th>
          </tr>
          <tr>
            <th>{{$t('tax_declaration_report.tax')}}</th>
            <th>{{$t('tax_declaration_report.sales')}}</th>
            <th>{{$t('tax_declaration_report.edits')}}</th>
            <th>{{$t('tax_declaration_report.tax_due')}}</th>
          </tr>

          <template v-if="dataForExportDetails[0] && dataForExportDetails[0].items">
            <tr  v-for="(_row, _index) in dataForExportDetails[0].items" :key="'sales'+_index">
              <th>{{_row.tax_name}}</th>
              <th>{{_row.total_invoice_applied_amount}}</th>
              <th>{{_row.total_credit_applied_amount}}</th>
              <th>{{_row.tax_values}}</th>
            </tr>
          </template>
          <tr v-if="dataForExportDetails[0]">
            <th>{{ $t('tax_report.total') }}</th>
            <th>{{dataForExportDetails[0].total_invoice_applied_amount}}</th>
            <th>{{dataForExportDetails[0].total_credit_applied_amount}}</th>
            <th>{{dataForExportDetails[0].total_tax_values}}</th>
          </tr>
          <tr>
            <th colspan="4">{{$t('tax_declaration_report.purchases')}}</th>
          </tr>
          <tr>
            <th>{{$t('tax_declaration_report.tax')}}</th>
            <th>{{$t('tax_declaration_report.purchases')}}</th>
            <th>{{$t('tax_declaration_report.edits')}}</th>
            <th>{{$t('tax_declaration_report.payable_tax')}}</th>
          </tr>
          <template v-if="dataForExportDetails[1] && dataForExportDetails[1].items">
            <tr  v-for="(_row, _index) in dataForExportDetails[1].items" :key="'sales'+_index">
              <th>{{_row.tax_name}}</th>
              <th>{{_row.total_invoice_applied_amount}}</th>
              <th>{{_row.total_credit_applied_amount}}</th>
              <th>{{_row.tax_values}}</th>
            </tr>
          </template>
          <tr v-if="dataForExportDetails[1]">
            <th>{{ $t('tax_report.total') }}</th>
            <th>{{dataForExportDetails[1].total_invoice_applied_amount}}</th>
            <th>{{dataForExportDetails[1].total_credit_applied_amount}}</th>
            <th>{{dataForExportDetails[1].total_tax_values}}</th>
          </tr>


          </tbody>
          <tfoot>
          <tr>
            <th colspan="3">{{ $t('tax_report.total') }}</th>
            <td>{{ dataTotalsForExportDetails.total_tax_values ? dataTotalsForExportDetails.total_tax_values : 0 }}</td>
          </tr>
          </tfoot>

        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'TaxDeclarationReport'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-tax-declaration-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/taxes',
      mainRouteDependency: 'base/dependency',



      data_details: [],
      details_total: [],

      dataForExportDetails: [],
      dataTotalsForExportDetails: [],
      filters: {
        from_date: null,
        to_date: null,

        f_branches_ids: null,
        f_taxes_ids: null,
        f_currencies_ids: null,

        period: null,
        range: null,
        f_time_period: 0,
      },
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      taxes: [],
      branches_ids: null,
      branches: [],
      taxes_ids: null,
      currencies: [],
      currency_default_id: null,
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.tax_declaration_report")}]);
    this.defaultDataForUser();
    this.getCurrencies();
    this.getTaxes();
    this.getBranches();
    },
  methods: {
    ...cssStypeForPrintReport,
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    getIds(related_with) {
      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.id;
          break;
        case 'taxes':
          this.filters.f_taxes_ids = this.taxes_ids.id;
          break;
      }
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportDetails();
    },
    doFilter() {
      this.page = 1;
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.from_date = null;
      this.filters.to_date = null;

      this.filters.f_branches_ids = null;
      this.filters.f_taxes_ids = null;
      this.filters.f_currencies_ids = this.currency_default_id;
      this.taxes_ids = null;
      this.branches_ids = null;

      this.doFilter();

    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.filters.f_currencies_ids = response.data.data.currency_id;
        this.currency_default_id = response.data.data.currency_id;
      });
    },

    getTaxes() {
      ApiService.get(`${this.mainRouteDependency}/taxes`).then((response) => {
        this.taxes = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    uniqueKey(){
      return  Math.floor(Math.random() * 10000000000) + '_'+ Date.now();
    },

    /*****
     * details
     */

    getReportDetails() {
      let _limit = (this.page ? this.page : 1) * 5
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_details = response.data.data.data;
        this.details_total = response.data.data.totals;
      });
    },
    getAllDataForExportDetails(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataForExportDetails = response.data.data.data;
        this.dataTotalsForExportDetails = response.data.data.totals;

        this.convertTableToExcelReport(tableId);
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'tax declaration report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'tax declaration report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.tax_declaration_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
